@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;




html[lang="es-US"] {
  @media#{$medium-only} {
    .title-heading{
      font-size: 2.4vw !important; 
    }
  }
}


#shareTop {
  width: 57px;
}

[data-sparta-container] {

  .spa-btn {
    font-family: $roboto-family, $roboto-font-fallbacks;
  }
}